import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Label, Image, Popup } from 'semantic-ui-react';

const FreshnessIndicator = ({changed}) => (
  <Popup
    trigger={<Label className="pulsating-circle" circular color="green" empty />}
    className="tutorial--freshness"
    size="small"
    basic
  >
    This tutorial is up-to-date.{' '}
    <br />
    <em>
      Last reviewed:{' '}
      {changed.toLocaleString('en-US', { month: 'long', year: 'numeric' })}
    </em>
  </Popup>
);

const TutorialHeader = ({ title, changed, previous, next, up }) => (
  <>
    <Grid.Row>
      <Grid.Column computer={11} tablet={11} mobile={12}>
        <div className="tutorial--headline">
          <Header as="h1">{title}</Header>
          <FreshnessIndicator changed={changed} />
        </div>
      </Grid.Column>
    </Grid.Row>
  </>
);

TutorialHeader.propTypes = {
  title: PropTypes.string.isRequired,
  changed: PropTypes.instanceOf(Date).isRequired,
  previous: PropTypes.shape({
    title: PropTypes.string,
    path: PropTypes.shape({
      alias: PropTypes.string,
    }),
  }),
  next: PropTypes.shape({
    title: PropTypes.string,
    path: PropTypes.shape({
      alias: PropTypes.string,
    }),
  }),
  up: PropTypes.shape({
    title: PropTypes.string,
    path: PropTypes.shape({
      alias: PropTypes.string,
    }),
  }),
};

TutorialHeader.defaultProps = {
  previous: {
    title: null,
    path: {
      alias: null,
    },
  },
  next: {
    title: null,
    path: {
      alias: null,
    },
  },
  up: {
    title: null,
    path: {
      alias: null,
    },
  },
};

export default TutorialHeader;
