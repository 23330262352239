import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Image, Label } from 'semantic-ui-react';

// Styling for code syntax highlighting.
import Prism from 'prismjs';
import TutorialAccessDenied from '../Tutorial/TutorialAccessDenied';
import TutorialPrevNext from "./TutorialPrevNext";
import TutorialHeader from "./TutorialHeader";

import 'prismjs/themes/prism.css';

/**
 * Render a tutorial.
 *
 * Initially render a tutorial teaser using the <TutorialTeaser/> component,
 * with a placeholder graphic underneath. Then once the data has been loaded
 * from the server use that content instead.
 *
 * For use with <DrupalTutorial />.
 */
class Tutorial extends React.Component {
  componentDidMount() {
    Prism.highlightAll();
  }

  render() {
    const { error, summary, body } = this.props;

    return (
      <Grid columns={1} stackable>
        <TutorialHeader {...this.props} />
        {error && (
          <Grid.Row>
            <Grid.Column computer={12} tablet={12} mobile={12}>
              <Label basic color="red">
                Unable to load tutorial content. {error.toString()}
              </Label>
            </Grid.Column>
          </Grid.Row>
        )}

        <Grid.Row>
          <Grid.Column computer={11} tablet={11} mobile={12} relaxed="very">
            {summary && <div dangerouslySetInnerHTML={{ __html: summary }} />}
            <p />

            {body ? (
              <div dangerouslySetInnerHTML={{ __html: body }} />
            ) : (
              <TutorialAccessDenied />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

Tutorial.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  body: PropTypes.string,
  changed: PropTypes.instanceOf(Date).isRequired,
  error: PropTypes.object,
};

export default Tutorial;
