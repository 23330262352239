import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Placeholder } from 'semantic-ui-react';
import TutorialTeaser from './TutorialTeaser';

const TutorialLoading = props => (
  <>
    <TutorialTeaser {...props} />
    <Grid columns={1} centered>
      <Grid.Row>
        <Grid.Column computer={7} tablet={10} mobile={12} relaxed="very">
          <p />
          <Placeholder fluid color="blue">
            <Placeholder.Header>
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
            <Placeholder.Header>
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
);

TutorialLoading.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
};

export default TutorialLoading;
