import React, { Component, createRef } from 'react';
import { Link } from 'gatsby';
import {
  Container,
  Grid,
  Icon,
  Ref,
} from 'semantic-ui-react';

import DrupalTutorial from '../drupal-components/DrupalTutorial';
import withDrupalOauthConsumer from '../drupal-oauth/withDrupalOauthConsumer';
import SignupNag from '../SignupNag/SignupNag';
import FeedbackForm from '../FeedbackForm/FeedbackForm';

// Site specific imports.
import SEO from '../seo';
import Tutorial from './Tutorial';
import TutorialTeaser from './TutorialTeaser';
import TutorialComingSoon from './TutorialComingSoon';
import TutorialLoading from './TutorialLoading';
import TutorialPrevNext from './TutorialPrevNext';
import Layout from '../layout';

import './TutorialTemplate.less';
import './FeedbackForm.less';

// Wrap the DrupalTutorial component so it has access to the DrupalOauthClient.
const DrupalTutorialWithOauthConsumer = withDrupalOauthConsumer(DrupalTutorial);

/**
 * The layout for the main content area of a tutorial page.
 */
class TutorialTemplate extends Component {
  contextRef = createRef();

  constructor(props) {
    super(props);
    this.state = {navigationIsCollapsed: false};

    // This binding is necessary to make `this` work in the callback
    this.toggleNavigation = this.toggleNavigation.bind(this);
  }

  toggleNavigation() {
    this.setState(prevState => ({navigationIsCollapsed: !prevState.navigationIsCollapsed}));
  }

  render() {
    const { navigationIsCollapsed } = this.state;
    const { userAuthenticated } = this.props;
    const { nodeTutorial: tutorial } = this.props.data;
    const collection = tutorial.relationships.node__collection
      ? tutorial.relationships.node__collection[0]
      : false;

    // Figure out what the previous and next tutorials in this collection are, if
    // any so we can pass that information down.
    let previous;
    let next;
    if (collection) {
      const len = collection.relationships.tutorials.length;
      const currentIdx = collection.relationships.tutorials.findIndex(
        (e, i) => e.drupal_id === tutorial.drupal_id
      );
      if (currentIdx !== 0) {
        previous =
          collection.relationships.tutorials[(currentIdx + len - 1) % len];
      }

      if (currentIdx < len - 1) {
        next = collection.relationships.tutorials[(currentIdx + 1) % len];
      }
    }

    return (
      <>
        <SEO
          title={tutorial.title}
          description={tutorial.short_description}
          image={
            tutorial.relationships.promotional_image &&
            tutorial.relationships.promotional_image.relationships.imageFile
              .localFile.childImageSharp.original.src
          }
          meta={[{ name: 'og:type', content: 'article' }]}
        />
        <Ref innerRef={this.contextRef}>
          <Container className="tutorial">
            <Grid stackable columns={2}>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Container className="tutorial--body">
                    <DrupalTutorialWithOauthConsumer
                      id={tutorial.drupal_id}
                      title={tutorial.title}
                      summary={tutorial.summary.processed}
                      body={tutorial.body ? tutorial.body.processed : false}
                      changed={new Date(tutorial.changed)}
                      tutorialAccess={tutorial.tutorial_access}
                      drupalOauthClient={this.props.drupalOauthClient}
                      tutorialComponent={Tutorial}
                      teaserComponent={TutorialTeaser}
                      loadingComponent={TutorialLoading}
                      comingSoonComponent={TutorialComingSoon}
                      previous={previous}
                      next={next}
                      up={collection}
                    />

                    {!userAuthenticated && <SignupNag />}
                  </Container>
                  {(tutorial.tutorial_access === 'public' ||
                    userAuthenticated) &&
                    tutorial.tutorial_access !== 'coming_soon' && (
                      <Grid centered className="tutorial--feedback">
                        <Grid.Row>
                          <Grid.Column computer={7} tablet={10} mobile={12}>
                            <FeedbackForm
                              drupalOauthClient={this.props.drupalOauthClient}
                              tutorialId={tutorial.drupal_internal__nid}
                              tutorialUrl={tutorial.path.alias}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    )}
                  <Container className="tutorial--previous-next">
                    <TutorialPrevNext
                      previous={previous}
                      next={next}
                      up={collection}
                    />
                  </Container>
                </Grid.Column>
                <Grid.Column computer={4} tablet={4} mobile={12}>
                  {collection &&
                    <div className={"tutorial--navigation" + (navigationIsCollapsed ? ' collapsed' : ' open' )}>
                      <div className="tutorial--navigation--header" onClick={this.toggleNavigation}>
                        <span>{collection.title}</span>
                        {navigationIsCollapsed
                          ? <Icon name="bars" />
                          : <Icon name="chevron down" />
                        }
                        
                      </div>
                      <ul>
                        {collection.relationships.tutorials.map((item, index) => {
                            const isActive = !!(item.drupal_id === tutorial.drupal_id);
                            let classes = [item.tutorial_access];
                            if (isActive) {
                              classes.push('active');
                            }


                            return (
                              <li
                                key={item.drupal_id}
                                className={classes.join(' ')}
                              >
                                <Link to={`${item.path.alias}/`}>{item.title}</Link>
                              </li>
                            );
                          })}                    
                      </ul>
                    </div>
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Ref>
      </>
    );
  }
}

// Wrap the tutorial so it has access to the userAuthenticated state stored in
// context.
const TutorialWithOAuthConsumer = withDrupalOauthConsumer(TutorialTemplate);

// Wrap the tutorial content with layout.
const TutorialWithLayout = (props) => (
  <Layout>
    <TutorialWithOAuthConsumer {...props} />
  </Layout>
);

// Tada!
export default TutorialWithLayout;
