import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Grid, Icon } from 'semantic-ui-react';

export const TutorialPrevNextIconsOnly = ({ previous, next, up }) => (
  <>
    {previous && (
      <Link to={`${previous.path.alias}/`} title={previous.title}>
        <Icon name="triangle left" size="big" aria-label={previous.title} />
      </Link>
    )}
    {up && (
      <Link to={`${up.path.alias}/`} title={`Back to ${up.title}`}>
        <Icon name="circle" size="small" aria-label="Back to Contents" />
      </Link>
    )}
    {next && (
      <Link to={`${next.path.alias}/`} title={next.title}>
        <Icon name="triangle right" size="big" aria-label={next.title} />
      </Link>
    )}
  </>
);

export const TutorialPrevNextFull = ({ previous, next, up }) => (
  <Grid columns={1} centered>
    <Grid.Row>
      <Grid.Column computer={11} tablet={11} mobile={12} relaxed="very">
        <Grid centered columns={3} className="tutorial--previous-next-content">
          <Grid.Row>
            <Grid.Column textAlign="left">
              {previous && (
                <Link to={`${previous.path.alias}/`}>
                  <Icon name="angle double left" color="red" /> {previous.title}
                </Link>
              )}
            </Grid.Column>
            <Grid.Column textAlign="center">
              {up && (
                <Link to={up.path.alias}>
                  <Icon name="angle double up" color="red" />
                  <br />
                  Back to Contents
                </Link>
              )}
            </Grid.Column>
            <Grid.Column textAlign="right">
              {next && (
                <Link to={`${next.path.alias}/`}>
                  <span>{next.title}</span>{' '}
                  <Icon name="angle double right" color="red" />
                </Link>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export const TutorialPrevNext = ({ previous, next, up, iconsOnly }) => {
  if (iconsOnly) {
    return (
      <TutorialPrevNextIconsOnly up={up} previous={previous} next={next} />
    );
  }

  return <TutorialPrevNextFull up={up} previous={previous} next={next} />;
};

TutorialPrevNext.propTypes = {
  iconsOnly: PropTypes.bool,
  previous: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.shape({
      alias: PropTypes.string.isRequired,
    }),
  }),
  next: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.shape({
      alias: PropTypes.string.isRequired,
    }),
  }),
  up: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.shape({
      alias: PropTypes.string.isRequired,
    }),
  }),
};

TutorialPrevNext.defaultProps = {
  iconsOnly: false,
};

export default TutorialPrevNext;
