import React from 'react';
import PropTypes from 'prop-types';
import Prism from 'prismjs';
import { Container, Grid, Header, Icon, Message, Segment } from 'semantic-ui-react';
import TutorialHeader from './TutorialHeader';

// Styling for code syntax highlighting.
require('prismjs/themes/prism.css');

class TutorialComingSoon extends React.Component {
  componentDidMount() {
    Prism.highlightAll();
  }

  render() {
    const { summary } = this.props;

    return (
      <Grid columns={1} centered>
        <Grid.Row>
          <Grid.Column computer={7} tablet={10} mobile={12} relaxed>
            <Message icon>
              <Icon name="clock outline" color="red" />
              <Message.Content>
                <Message.Header>Coming soon ...</Message.Header>
                We're constantly working on creating new content. This tutorial is currently being written and will be available soon.
              </Message.Content>
            </Message>
          </Grid.Column>
        </Grid.Row>

        <TutorialHeader {...this.props} />

        <Grid.Row>
          <Grid.Column computer={7} tablet={10} mobile={12} relaxed>
            <Header as="h2">Summary</Header>
            <div dangerouslySetInnerHTML={{ __html: summary }} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

TutorialComingSoon.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  changed: PropTypes.instanceOf(Date).isRequired,
};

export default TutorialComingSoon;
