import React from 'react';
import PropTypes from 'prop-types';
import Prism from 'prismjs';
import { Grid, Header, Image } from 'semantic-ui-react';
import TutorialHeader from './TutorialHeader';

// Styling for code syntax highlighting.
require('prismjs/themes/prism.css');

class TutorialTeaser extends React.Component {
  componentDidMount() {
    Prism.highlightAll();
  }

  render() {
    const { summary } = this.props;

    return (
      <Grid columns={1} centered>
        <TutorialHeader {...this.props} />
        <Grid.Row>
          <Grid.Column computer={11} tablet={11} mobile={12} relaxed="very">
            <div dangerouslySetInnerHTML={{ __html: summary }} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <div className="tutorial--auth-required-banner">
              Log in or Sign up to view the complete tutorial.
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

TutorialTeaser.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  changed: PropTypes.instanceOf(Date).isRequired,
};

export default TutorialTeaser;
